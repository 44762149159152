body {
    .signupContainer {
      padding: 6rem 0;
    }
  
    .signupFormTitleBox {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
  
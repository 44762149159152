body {
    .loginForm {
      display: flex;
      flex-direction: column;
    }
  
    .loginButton {
      margin: 20px 0 0 auto;
      padding: 15px;
      border-radius: 50px;
    }

    .signup {
        margin: 20px 10px 0 auto;
      }

    .loginFormBox {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
    }

  }


  
body {
  .navbar {
    padding: 20px 0;
    transition: 0.2s all;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    position: relative;
  }

  .link {
    text-decoration: none;
  }

  .contactButton {
    padding: 15px;
    width: 165px;
    border-radius: 50px;
  }

  .pricingButton {
    padding: 15px;
    width: 165px;
    border-radius: 50px;
  }

  .hambugerMenu {
    position: relative;
  }

  .getStartedButton {
    padding: 15px;
    border-radius: 50px;
    width: 290px;
    background-color: #ff9800;
  }

}

body {
  .main {
    display: flex;
    flex-direction: column;
    gap: 7rem;
  }

  .header {
    background-image: url("../../assets/home/main-bg2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .headerContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerInfoSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .headerLearnButton {
    padding: 15px;
    border-radius: 50px;
    min-width: 188px;
  }

  .headerInfographicSection {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .salesMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .salesMessageInfographic {
    padding-top: 15px;
  }

  .salesAutomation {
    position: relative;
  }

  .salesAutomationInfo {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .scalingRoi {
    position: relative;
  }

  .scalingRoiInfo {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .contextualMessaging {
    position: relative;
  }

  .contextualMessagingContainer {
    position: relative;
  }

  .contextualMessagingInfo {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .salesOutreach {
    position: relative;
  }

  .salesOutreachContainer {
    position: relative;
  }

  .salesOutreachInfo {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .whySaleslightSection {
    padding: 8rem 0 20rem 0;
    background-image: linear-gradient(#ebf3fb 90%, #fff);
    position: relative;
  }

  .whySaleslightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    position: relative;
  }

  .whySaleslightItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    padding: 0 40px;
    background-color: #fff;
    border-radius: 15px;
    min-height: 280px;
  }

  .trySaleslight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    position: relative;
  }

  .getStartedButton {
    padding: 15px;
    border-radius: 50px;
    width: 290px;
  }

  img {
    width: 100%;
    object-fit: fill;
  }

  .ytAcademy {
    padding: 15px;
    border-radius: 50px;
    background-color: #ff9800;
  }
}

@media screen and (max-width: 768px) {
  img {
    width: 100vw
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  img {
    width: 50vw
  }
}

@media screen and (min-width: 1201px) {
  img {
    width: 33vw
  }
}


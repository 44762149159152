body {
  .main {
    padding: 3rem 0;
  }

  img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  img {
    width: 100vw;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  img {
    width: 50vw;
  }
}

@media screen and (min-width: 1201px) {
  img {
    width: 33vw;
  }
}

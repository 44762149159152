body {
    .pricingCheckoutSuccessContainer {
        // padding: 18rem 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        position: relative;
      }
  }
  
body {
  .footer {
    padding: 4rem 0;
  }

  .link {
    text-decoration: none;
  }

  .footerBottom {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .link {
    font-size: 0.25rem;
  }

  .footerBottom {
    font-size: 0.25rem;
    background-color: red;
  }
}


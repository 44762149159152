body {
  .contactForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .contactButton {
    margin: 0 0 0 auto;
    padding: 15px;
    border-radius: 50px;
  }
}

body {
    .main {
      display: flex;
      flex-direction: column;
    }
  
    .header {
      background-image: url("../../../assets/home/main-bg2.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
    }
  
    .headerContainer {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .headerInfoSection {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  
    .headerLearnButton {
      padding: 15px;
      border-radius: 50px;
      width: 188px;
    }
  
    .headerInfographicSection {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .salesMessage {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  
    .salesMessageInfographic {
      padding-top: 15px;
    }
  
    .salesAutomation {
      position: relative;
    }
  
    .salesAutomationInfo {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  
    .scalingRoi {
      position: relative;
    }
  
    .scalingRoiInfo {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  
    .contextualMessaging {
      position: relative;
    }
  
    .contextualMessagingContainer {
      position: relative;
    }
  
    .contextualMessagingInfo {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  
    .salesOutreach {
      position: relative;
    }
  
    .salesOutreachContainer {
      position: relative;
    }
  
    .salesOutreachInfo {
      display: flex;
      flex-direction: column;
      gap: 15px;    
    }
  
    .whySaleslightSection {
      display: grid;
      background: linear-gradient(to bottom, #ebf3fb 18%, #fff 0%);
      position: relative;
    }
  
    .whySaleslightPlanSection {
      display: grid;
      background-image: #fff;
      position: relative;
    }
  
    .whySaleslightContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4rem;
      position: relative;
      padding-bottom: 5px;
    }
  
    .whySaleslightItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15px;
      padding: 0 60px;
      background-color: #fff;
      min-height: 400px;
    }
  
    .borderRadiusLeft {
      border-radius: 17px 0px 0px 17px;
    }
  
    .borderRadiusRight {
      border-radius: 0px 17px 17px 0px;
    }  
  
    .salesLightPlanContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  
    .salesLightPlanHeader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 25px;
      padding: 0 20px;
      background-color: #1976d2;
      min-height: 50px;
      border: 0.5px solid #fff;
    }
  
    .borderRadiusHeaderLeft {
      border-radius: 17px 0px 0px 0px;
    }

    .borderRadiusHeader {
      border-radius: 17px 17px 0px 0px;
    }
  
    .borderRadiusHeaderRight {
      border-radius: 0px 17px 0px 0px;
    }  
  
    .salesLightPlanItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      gap: 15px;
      padding: 0 20px;
      background-color: #EBF3FB;
      border: 0.5px solid #fff;
    }
  
    .selectPlanBox {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 70px;
        background-color: #fff;
        min-height: 400px;
    }
  
    .selectPlanButton {
      width: 250px;
      height: 50px;
      border-radius: 40px;
    }
  
    .trySaleslight {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      position: relative;
    }
  
    .getStartedButton {
      padding: 15px;
      border-radius: 50px;
      width: 290px;
    }
  
    img {
      width: 100%;
      object-fit: fill;
    }
  }
  
  @media screen and (max-width: 768px) {
    img {
      width: 100vw
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    img {
      width: 50vw
    }
  }
  
  @media screen and (min-width: 1201px) {
    img {
      width: 33vw
    }
  }
  
  
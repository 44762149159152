@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");


*, ::before, ::after {
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2.5rem;
    padding: 3rem 10px;
  }

  .linkText {
    text-decoration: none;
  }

  .contactButton {
    padding: 15px;
    width: 200px;
    border-radius: 50px;
  }
}

body {
  .main {
    padding: 3rem 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem auto;
    width: 80%;

    h1 {
      font-size: 1.5rem;
      font-weight: 500;
    }

    h2 {
      font-size: 1.25rem;
      margin: 1rem 0;
      font-weight: 400;
    }

    p {
      margin: 1rem 1rem;
    }

    ul {
      margin: 1rem 1rem;
    }
  }
}

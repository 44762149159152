body {
    .signupForm {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  
    .signupButton {
      margin: 0 0 0 auto;
      padding: 15px;
      border-radius: 50px;
    }    

    .signup {
      margin: 0px 10px 0 auto;
    }

    .loginFormBox {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
    }

  }


  
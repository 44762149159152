body {
  .contactContainer {
    padding: 6rem 0;
  }

  .contactFormTitleBox {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
